<template>
  <div class="laws">
    <div class="laws-wrap">
      <div class="laws-title">
        法律法规
        <div class="laws-en-title">LAWS AND REGULATIONS</div>
      </div>
      <div class="laws-content">
        <ul class="laws-content-top">
          <li class="content-item" v-for="(item, i) in topContentInfo" :key="i" @mouseenter="item.imgFlag = true" @mouseleave="item.imgFlag = false">
            <a :href="item.link" class="content-item-link"></a>
            <img class="content-item-left" :src="item.imgFlag ? item.darkIconUrl : item.lightIconUrl">
            <div class="content-item-right">
              <div class="content-item-title">{{item.title}}</div>
              <div class="content-item-en-title">{{item.enTitle}}</div>
            </div>
          </li>
        </ul>
        <ul class="laws-content-bottom">
          <li class="content-item" v-for="(item, i) in bottomContentInfo" :key="i" @mouseenter="item.imgFlag = true" @mouseleave="item.imgFlag = false">
            <a :href="item.link" class="content-item-link"></a>
            <img class="content-item-left" :src="item.imgFlag ? item.darkIconUrl : item.lightIconUrl">
            <div class="content-item-right">
              <div class="content-item-title">{{item.title}}</div>
              <div class="content-item-en-title">{{item.enTitle}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Laws',
    data() {
      return {
        topContentInfo: [
          {
            lightIconUrl: require('@assets/img/antithunder/light_01.png'),
            darkIconUrl: require('@assets/img/antithunder/dark_01.png'),
            title: '法律',
            enTitle: 'Law',
            imgFlag: false,
            link: '#'
          },
          {
            lightIconUrl: require('@assets/img/antithunder/light_02.png'),
            darkIconUrl: require('@assets/img/antithunder/dark_02.png'),
            title: '行政法规',
            enTitle: 'Administrative regulations',
            imgFlag: false,
            link: '#'
          },
          {
            lightIconUrl: require('@assets/img/antithunder/light_03.png'),
            darkIconUrl: require('@assets/img/antithunder/dark_03.png'),
            title: '部门规章',
            enTitle: 'Departmental rules',
            imgFlag: false,
            link: '#'
          }
        ],
        bottomContentInfo: [
          {
            lightIconUrl: require('@assets/img/antithunder/light_04.png'),
            darkIconUrl: require('@assets/img/antithunder/dark_04.png'),
            title: '地方性法规',
            enTitle: 'Local regulations',
            imgFlag: false,
            link: '#'
          },
          {
            lightIconUrl: require('@assets/img/antithunder/light_05.png'),
            darkIconUrl: require('@assets/img/antithunder/dark_05.png'),
            title: '地方政府规章',
            enTitle: 'Local government regulations',
            imgFlag: false,
            link: '#'
          },
          {
            lightIconUrl: require('@assets/img/antithunder/light_06.png'),
            darkIconUrl: require('@assets/img/antithunder/dark_06.png'),
            title: '收费标准',
            enTitle: 'Charges',
            imgFlag: false,
            link: '#'
          }
        ],
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .laws {
    width: 100%;
    height: 550px;
    background-color: #cdd8f6;
    padding-top: 110px;
    .laws-wrap {
      width: 1200px;
      height: 300px;
      margin: 0 auto;
      .laws-title {
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        color: #1b204e;
        .laws-en-title {
          font-size: 16px;
          color: #1b204e;
        }
      }
      .laws-content {
        width: 1032px;
        height: 150px;
        margin: 50px auto 0;
        .laws-content-top {
          border-bottom: 2px solid #565566;
        }
        .laws-content-bottom {
          .content-item {
            padding-top: 10px;
          }
        }
        .laws-content-top,
        .laws-content-bottom {
          width: 100%;
          height: 65px;
          .content-item {
            position: relative;
            float: left;
            height: 100%;
            &:nth-of-type(1)  {
              width: 316px;
              border-right: 2px solid #565566;
            }
            &:nth-of-type(2)  {
              width: 402px;
              padding-left: 85px;
              border-right: 2px solid #565566;
            }
            &:nth-of-type(3)  {
              width: 314px;
              padding-left: 85px;
            }
            .content-item-link {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            .content-item-left {
              display: block;
              float: left;
              width: 50px;
              height: 50px;
            }
            .content-item-right {
              float: left;
              margin-left: 12px;
              padding-top: 3px;
              .content-item-title {
                font-size: 20px;
                line-height: 24px;
                color: #565566;
                // 元素宽度自适应内容大小
                width: fit-content;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: -ms-fit-content;
              }
              .content-item-en-title {
                font-size: 14px;
                color: #565566;
                padding-top: 4px;
              }
            }
            &:hover {
              .content-item-right {
                .content-item-title {
                  color: #1b204e;
                  font-weight: 700;
                  border-bottom: 2px solid #1b204e;
                }
                .content-item-en-title {
                  color: #1b204e;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
</style>