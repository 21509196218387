<template>
  <div class="latest-news">
    <div class="latest-news-wrap">
      <div class="latest-news-top">
        <div class="ln-headline">最新咨询</div>
        <div class="ln-en-headline">LATEST NEWS</div>
      </div>
      <div class="latest-news-bottom">
        <div class="ln-content-wrap">
          <div class="ln-content" v-for="(item, index) in newsInfo" :key="item.id" :ref="index">
            <ul class="ln-content-left">
              <li class="ln-content-item" v-for="(contentItem) in item.content" :key="contentItem.id">
                <span class="item-title">{{contentItem.title}}</span>
                <span class="item-time">{{contentItem.time}}</span>
              </li>
            </ul>
            <img src="@assets/img/antithunder/news.jpeg" class="ln-content-right">
          </div>
        </div>
        <button class="pre" :class="preButtonDis ? 'undisable-button' : ''" ref="preButton" @click="prePage(pageFlag)" :disabled='preButtonDis'></button>
        <button class="next" :class="nextButtonDis ? 'undisable-button' : ''" ref="nextButton" @click="nextPage(pageFlag)" :disabled='nextButtonDis'></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LatestNews',
  data() {
    return {
      newsInfo: [
        {
          id: '1',
          content: [
            {id: '1', title: '防雷中心克服困难 提前开展首都机场检测', time: '2018-04-08'},
            {id: '2', title: '防雷中心完成司马台长城防雷服务保障工作', time: '2018-04-04'},
            {id: '3', title: '防雷中心完成易燃易爆场所新领域服务保障', time: '2018-02-20'},
            {id: '4', title: '防雷中心组织防雷检测技术培训', time: '2018-02-01'},
            {id: '5', title: '防雷中心召开2018年工作启动会', time: '2018-02-01'},
            {id: '6', title: '防雷中心“火”力全开保障燕房线', time: '2017-08-03'},
          ]
        },
        {
          id: '2',
          content: [
            {id: '1', title: '防雷中心克服困难 提前开展首都机场检测2', time: '2018-04-08'},
            {id: '2', title: '防雷中心完成司马台长城防雷服务保障工作2', time: '2018-04-04'},
            {id: '3', title: '防雷中心完成易燃易爆场所新领域服务保障2', time: '2018-02-20'},
            {id: '4', title: '防雷中心组织防雷检测技术培训2', time: '2018-02-01'},
            {id: '5', title: '防雷中心召开2018年工作启动会2', time: '2018-02-01'},
            {id: '6', title: '防雷中心“火”力全开保障燕房线2', time: '2017-08-03'},
          ]
        },
        {
          id: '3',
          content: [
            {id: '1', title: '防雷中心克服困难 提前开展首都机场检测333', time: '2018-04-08'},
            {id: '2', title: '防雷中心完成司马台长城防雷服务保障工作33', time: '2018-04-04'},
            {id: '3', title: '防雷中心完成易燃易爆场所新领域服务保障3', time: '2018-02-20'},
            {id: '4', title: '防雷中心组织防雷检测技术培训3', time: '2018-02-01'}
          ]
        }
      ],
      pageFlag: 0,
      preButtonDis: false,
      nextButtonDis: false,
    }
  },
  mounted() {
    this.prePage(this.pageFlag)
  },
  methods: {
    prePage(page) {
      if(page == 0 ) {
        this.preButtonDis = true;
        this.nextButtonDis = false;

        // this.$refs[变量][0] 获取动态绑定ref的元素
        this.$refs[page][0].style.display = 'block'
        return
      } else {
        this.preButtonDis = false;
        this.nextButtonDis = false;

        this.$refs[page][0].style.display = 'none'
        this.$refs[page-1][0].style.display = 'block'
        return this.pageFlag = page - 1
      }
    },
    nextPage(page) {
      if(page == this.newsInfo.length-1) {
        this.nextButtonDis = true;
        this.preButtonDis = false;

        this.$refs[page][0].style.display = 'block'
        return
      } else {
        this.nextButtonDis = false;
        this.preButtonDis = false;

        this.$refs[page][0].style.display = 'none'
        this.$refs[page+1][0].style.display = 'block'
        
        return this.pageFlag = page + 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>

<style lang="scss" scoped>
  .latest-news {
    width: 100%;
    height: 750px;
    padding-top: 105px;
    background-color: #141f2c;
    .latest-news-wrap {
      width: 1200px;
      height: 500px;
      margin: 0 auto;
      text-align: center;
      .latest-news-top {
        .ln-headline {
          font-size: 34px;
          font-weight: 700;
          color: #ffffff;
        }
        .ln-en-headline {
          font-size: 16px;
          color: #ffffff;
        }
      }
      .latest-news-bottom {
        width: 1020px;
        height: 350px;
        margin: 70px auto 0;
        text-align: left;
        .ln-content-wrap  {
          width: 100%;
          height: 285px;
          margin-bottom: 18px;
          .ln-content {
            width: 100%;
            height: 100%;
            display: none;
            .ln-content-left {
              float: left;
              width: 500px;
              margin-right: 68px;
              .ln-content-item {
                position: relative;
                padding-left: 15px;
                line-height: 32px;
                margin-bottom: 10px;
                &::before {
                  position: absolute;
                  top: 14px;
                  left: 0;
                  content: '';
                  width: 4px;
                  height: 4px;
                  background-color: #fff;
                  border-radius: 50%;
                }
                .item-title {
                  margin-right: 30px;
                }
                .item-time {
                  font-size: 10px;
                }
              }
            }
            .ln-content-right {
              display: block;
              float: left;
              width: 450px;
              height: 280px;
            }
          }
        }

        .pre,
        .next {
          position: relative;
          display: inline-block;
          width: 44px;
          height: 44px;
          background-color: transparent;
          border: 2px solid #5163fe;
          &::after {
            position: absolute;
            top: 5px;
            left: 6px;
            content: '\e902';
            font-family: 'icomoon';
            font-size: 28px;
            color: #5163fe;
          }
          &:hover {
            background-color: #5163fe;
            &::after {
              color: #ffffff;
            }
          }
        }
        .pre {
          margin-right: 45px;
          &::after {
            content: '\e901';
          }
        }
        // 按钮禁用效果
        .undisable-button {
          border: 2px solid #aaa;
          &::after {
            color: #aaa;
          }
          // 禁用按钮无触发效果
          &:hover {
            background-color: transparent;
            &::after {
              color: #aaa;
            }
          }
        }
      }
    }
  }
</style>