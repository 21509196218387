import { render, staticRenderFns } from "./LatestNews.vue?vue&type=template&id=5544af26&scoped=true&"
import script from "./LatestNews.vue?vue&type=script&lang=js&"
export * from "./LatestNews.vue?vue&type=script&lang=js&"
import style1 from "./LatestNews.vue?vue&type=style&index=1&id=5544af26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5544af26",
  null
  
)

export default component.exports