<template>
  <div class="comprehensive-info">
    <div class="ci-wrap">
      <ul class="ci-item-wrap">
        <li class="ci-item" v-for="(item) in comprehensiveInfo" :key="item.id">
          <div class="ci-item-top">
            <div class="headline">{{item.headline}}</div>
            <div class="en-headline">{{item.enHeadline}}</div>
          </div>
          <div class="ci-item-bottom">
            <ul class="content-item-wrap">
              <li class="content-item" v-for="contentItem in item.content" :key="contentItem.id">
                <a  class="content-item-link" :href="contentItem.link"></a>
                <span class="content-item-title">{{contentItem.title}}</span>
                <span class="content-item-time" v-if="contentItem.time !== null">{{contentItem.time}}</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComprehensiveInfo',
  data() {
    return {
      comprehensiveInfo: [
        {
          id: '1',
          headline: '雷电案例',
          enHeadline: 'Thunder case',
          content: [
            {id: '1', title: '2013年雷电灾害统计', time: '2014-09-24', link: '#'},
            {id: '2', title: '2012年雷电灾害统计', time: '2014-09-24', link: '#'},
            {id: '3', title: '2011年雷电灾害统计', time: '2014-09-24', link: '#'},
            {id: '4', title: '2010年雷电灾害统计', time: '2014-09-24', link: '#'},
            {id: '5', title: '2009年雷电灾害统计', time: '2014-09-24', link: '#'},
            {id: '6', title: '2008年雷电灾害统计', time: '2014-09-24', link: '#'},
            {id: '7', title: '2007年雷电灾害统计', time: '2014-09-24', link: '#'}
          ]
        },
        {
          id: '2',
          headline: '雷电常识',
          enHeadline: 'Raiden common sense',
          content: [
            {id: '1', title: '易被雷电袭击的对象', time: null, link: '#'},
            {id: '2', title: '雷暴发生时在防雷设施建筑物附近，应注意', time: null, link: '#'},
            {id: '3', title: '建筑物防雷措施', time: null, link: '#'},
            {id: '4', title: '建筑物防雷措施', time: null, link: '#'},
            {id: '5', title: '安装避雷针是否还会被雷击', time: null, link: '#'},
            {id: '6', title: '建筑物防雷措施', time: null, link: '#'},
          ]
        },
        {
          id: '3',
          headline: '雷电科普',
          enHeadline: 'Raiden Science',
          content: [
            {id: '1', title: '避雷针的发明', time: null, link: '#'},
            {id: '2', title: '安装避雷针是否还会被雷击', time: null, link: '#'},
            {id: '3', title: '学生防雷知识', time: null, link: '#'},
            {id: '4', title: '雷电监测方法', time: null, link: '#'},
            {id: '5', title: '室内常识', time: null, link: '#'},
            {id: '6', title: '室外常识', time: null, link: '#'},
            {id: '7', title: '专家揭示人遭雷击的秘密', time: null, link: '#'}
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .comprehensive-info {
    width: 100%;
    height: 768px;
    padding-top: 120px;
    background: #1b2839;
    .ci-wrap {
      width: 1200px;
      margin: 0 auto;
      .ci-item-wrap {
        .ci-item {
          float: left;
          width: 380px;
          height: 500px;
          padding: 85px 45px 0;
          background-color: #cdd8f6;
          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin-right: 30px;
          }
          .ci-item-top {
            position: relative;
            margin-bottom: 50px;
            .headline {
              font-size: 20px;
              font-weight: 700;
              color: #1b204e;
            }
            .en-headline {
              color: #1b204e;
              letter-spacing: 0;
            }
            &::after {
              position: absolute;
              top: 70px;
              left: 0;
              content: '';
              width: 18px;
              height: 3px;
              background-color: #1b204e;
            }
          }
          .ci-item-bottom {
            .content-item-wrap {
              .content-item {
                position: relative;
                height: 20px;
                margin-bottom: 15px;
                color: #565566;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                .content-item-link {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 20px;
                }
                .content-item-title {
                  margin-right: 15px;
                  color: #565566;
                }
                .content-item-time {
                  font-size: 10px;
                  color: #565566;
                }

                // hover 触发状态
                &:hover {
                  .content-item-title,
                  .content-item-time {
                    color: #1b204e;
                    font-weight: 700;
                    border-bottom: 2px solid #1b204e;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>