<template>
  <div class="anti-thunder">
    <div class="banner">
      防雷科技
    </div>
    <Query></Query>
    <LatestNews></LatestNews>
    <Laws></Laws>
    <ComprehensiveInfo></ComprehensiveInfo>
    <Footer></Footer>
  </div>
</template>

<script>
  // import Banner from '@components/content/Banner.vue'
  import Query from './childComps/Query.vue'
  import Laws from './childComps/Laws.vue'
  import LatestNews from './childComps/LatestNews.vue'
  import ComprehensiveInfo from './childComps/ComprehensiveInfo.vue'
  import Footer from '@components/common/Footer.vue'

export default {
  name: 'AntiThunder',
  components: {
    Query,
    LatestNews,
    Laws,
    ComprehensiveInfo,
    Footer
  }
}
</script>

<style lang="scss" scoped>
  .anti-thunder {
    width: 100%;
    background-color: #1a2839;
    .banner {
      width: 100%;
      height: 800px;
      line-height: 800px;
      background-color: #141f2c;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }
  }
</style>