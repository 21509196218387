<template>
  <div class="query-wrap">
    <ul class="query-item-wrap">
      <li v-for="(item, index) in inquireItemInfo" :key="index" class="query-item">
        <a :href="item.link"></a>
        <img :src="item.iconUrl" alt="">
        <div class="title">{{item.title}}</div>
        <div class="en-title">{{item.enTitle}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Query',
  data() {
    return {
      inquireItemInfo: [
        {
          iconUrl: require('@assets/img/antithunder/jiance-icon.png'),
          title: '检测报告查询',
          enTitle: 'Test report query',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/antithunder/beian-icon.png'),
          title: '产品备案查询',
          enTitle: 'Product filing query',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/antithunder/yujing-icon.png'),
          title: '预警信息查询',
          enTitle: 'Warning information query',
          link: '#'
        },
        {
          iconUrl: require('@assets/img/antithunder/zizhi-icon.png'),
          title: '资质备案查询',
          enTitle: 'Qualification record query',
          link: '#'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .query-wrap {
    width: 100%;
    height: 350px;
    background-color: #cdd8f6;
    .query-item-wrap {
      width: 1200px;
      height: 350px;
      margin: 0 auto;
      .query-item {
        position: relative;
        float: left;
        width: 300px;
        padding-top: 110px;
        text-align: center;
        cursor: pointer;
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 300px;
          height: 266px;

        }
        img {
          display: block;
          width: 86px;
          height: 86px;
          margin: 0 auto 10px;
        }
        .title {
          width: 128px;
          font-size: 20px;
          font-weight: 400;
          color: #6272a7;
          margin: 0 auto 12px;
        }
        .en-title {
          font-size: 12px;
          color: #6272a7;
        }
        &:hover {
          .title {
            font-weight: 700;
            color: #2b2c66;
            border-bottom: 2px solid #2b2c66;
          }
          .en-title {
            font-weight: 700;
            color: #2b2c66;
          }
        }
      }
    }
  }
</style>